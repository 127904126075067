<template>
    <div id="login-container">
        <div class="login-content">
            <div class="title">Login</div>
            <div class="login-form">
                <div class="login-form-item">
                    <div class="form-label">Email</div>
                    <input type="text" v-model="userData.email">
                </div>
                <div class="login-form-item">
                    <div class="form-label">Password</div>
                    <input type="password" v-model="userData.pawssord">
                </div>
                <div class="action-btn" @click='handleLogin'>Login</div>
                <div class="action-tip">New customer? <router-link to="/account/register">Sign Up</router-link></div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import {login} from '@/api/mall/index.js'
export default {
    data() {
        return {
            userData:{
                email:'',
                pawssord:''
            }
        }
    },
    methods:{
        handleLogin(){
            login(JSON.stringify(this.userData)).then(res=>{
                if(res.data.code == 0){
                    this.changeLogin({
                        loginStatus:true
                    })
                    this.changeUserId({
                        userId:res.data.user.id
                    })
                    this.$router.push('/account/order')
                }
            })
        },
        ...mapActions([
            'changeLogin',
            'changeUserId'
        ])
    }
}
</script>

<style lang="stylus" scoped>
div#login-container
    background #fff
    color #222
    height 100%
    border-top 1px solid #a0a0a0
    .title 
        font-size 1.25rem
        text-align center
        padding-top 2.5rem
        padding-bottom 1.25rem            
    .login-content
        width 22.5rem
        margin: 0 auto
        .form-label 
            font-size .875rem
            margin-bottom .625rem
        .login-form-item
            input
                box-sizing border-box
                width 100%
                height 2.5rem
                line-height 2.5rem
                padding-left .625rem
                border 1px solid #dfdfdf
                margin-bottom .625rem
        .action-btn
            font-size 1rem
            background #000
            text-align center
            color #fff
            height 2.5rem
            line-height 2.5rem
            width 100%
            margin-top 1.25rem
        .action-tip
            font-size .875rem
            text-align center
            margin-top .625rem
            a
                font-weight 600
        
        
</style>
</style>
